import React, { Component } from 'react'
import { Layout, Input, Spin, List, notification, Modal } from 'antd'
import ReactDOM from 'react-dom'
import moment from "moment"
import InfiniteScroll from 'react-infinite-scroller'
import Header from "../../component/header"
import { Color } from '../../util/color'
import SliderUser from '../../component/sliderUser'
import SliderNotification from "../../component/sliderNoti"

import SiderFix from "../../component/siderFix"
import { GetDataNotification, GetDatauserMessage, GetDatauserMessageBYID, PostDataMessage } from "./action"

import { skt } from '../../util/socket.io'

import './styles/chat.css'
import FooterPage from "../../component/footer"


const { Sider } = Layout


class SiderDemo extends Component {

  state = {
    messages: [],
    dataUser: [],
    manualChat: true,
    chatStatus: 'Manual Chat',
    activeChat: '',
    fileName: '',
    chat: true,
    NotiData: [],
    collapsed: false,
    colorData: Color,
    MessageValue: '',
    loading: false,
    hasMore: true,
    activesider: 'Chat',
    Height_laout: 0,
    color: '#828282',
    secondColor: "#828282",
    colorActive: {},
    emptytext: '',
    unread_count: 0,
    page: 1,
    loadfrist: true,
    pageUser: 1,
    loadingUser: false,
    hasMoreUser: true,
    pageMessage: 1,
    loadingMessage: false,
    hasMoreMessage: true,
    loadefristUser: true,
    search: '',
    isread: true,
    bottomfrist: true,
    chActive: false,
    loadingLogout: false,
    visible: false,
    loadChatLeft:false
  };


  componentDidUpdate() {
    if (this.state.bottomfrist) {
      this.scrollToBottom();
    }
  }

  componentDidMount() {
    this.scrollToBottom();
    // get dataUser history chat
    this.getDataUser()
    // get data count Notification 
    GetDataNotification(this.state.page).then(data => {
      this.setState({
        unread_count: data.unread_count
      })
    })

    skt.on('question', (data) => {
      this.setState({
        loadChatLeft:true
      })
      console.log(data)
      this.setState({
        messages: [...this.state.messages, { ...data }],
        loadChatLeft:false
      })
      this.scrollToBottom()
    })

    skt.on('answer', (data) => {
      console.log(data)
      this.setState({
        messages: [...this.state.messages, { ...data }],
      })
      this.scrollToBottom()
    })

    skt.on('mark_as_read', (data) => {
      this.setState({
        unread_count: data.unread_count
      })
    })

    skt.on('notifications', (data) => {
      this.setState({
        unread_count: data.unread_count,
        NotiData: [{ ...data.payload }, ...this.state.NotiData]
      })

    })
  }

  scrollToBottom = () => {
    var len = this.state.messages.length - 1
    const node = ReactDOM.findDOMNode(this['_div' + len]);
    if (node) {
      node.scrollIntoView();
    }
  }

  scrollToBottomSet = () => {
    this.setState({
      chActive: false
    }, () => this.scrollToBottom())
  }


  onMouseEnter = () => {
    this.setState({
      color: '#104898'
    })
  }

  onMouseLeave = () => {
    this.setState({
      color: '#828282'
    })
  }

  onMouseSecond = () => {
    this.setState({
      secondColor: '#104898'
    })
  }

  onMouseLeaveSecond = () => {
    this.setState({
      secondColor: '#828282'
    })
  }

  onMouseLogout = () => {
    this.setState({
      secondColor: '#104898'
    })
  }

  onMouseLeaveLogout = () => {
    this.setState({
      secondColor: '#828282'
    })
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  // function for dataUser history 
  getDataUser = () => {
    GetDatauserMessage(this.state.pageUser).then(data => {
      if (data.payload.length === 0) {
        this.setState({
          dataUser: [...this.state.dataUser],
          loadingUser: false,
          hasMoreUser: false,
          loadefristUser: false,
          loadingMessage: false
        })
      } else {
        let Data = []
        for (let i = 0; i < data.payload.length; i++) {
          var rand = Math.floor(Math.random() * (Color.length - 0 + 1)) + 0;
          Data.push({
            sender_id: data.payload[i].sender_id,
            color: Color[rand],
            recipient_answer: data.payload[i].recipient_answer,
            sender_question: data.payload[i].sender_question,
            recipient_time: data.payload[i].recipient_time,
            sender_time: data.payload[i].sender_time,
            recipient_id: data.payload[i].recipient_id
          })
        }
        if (this.state.dataUser.length === 0) {
          this.setState({
            dataUser: [...Data],
            colorActive: Data[0].color,
            // activeChat: Data[0].sender_id,
            loadingUser: false,
            loadefristUser: false,
            sender_id: Data[0].sender_id,
            recipient_id: Data[0].recipient_id,
            // mID: 0
          })
          // this.getPageMessage(Data[0].sender_id)
        } else {
          this.setState({
            dataUser: [...this.state.dataUser, ...Data],
            colorActive: Data[0].color,
            loadingUser: false,
          })
        }
      }
    })
  }



  // function for Notificationdata
  getNotification = () => {
    GetDataNotification(this.state.page).then(data => {
      if (data.payload.state !== undefined) {
        this.setState({
          NotiData: [],
          emptytext: data.payload.reason,
          loadfrist: false
        })
      } else {
        if (data.payload.length === 0) {
          this.setState({
            loading: false,
            hasMore: false,
            loadfrist: false
          })
        } else {
          let Data = []
          for (let i = 0; i < data.payload.length; i++) {
            var rand = Math.floor(Math.random() * (Color.length - 0 + 1)) + 0;
            Data.push({
              _id: data.payload[i]._id,
              color: Color[rand],
              is_read: data.payload[i].is_read,
              recipient_answer: data.payload[i].recipient_answer,
              recipient_time: data.payload[i].recipient_time,
              sender_question: data.payload[i].sender_question,
              recipient_id: data.payload[i].recipient_id,
              sender_id: data.payload[i].sender_id,
              sender_time: data.payload[i].sender_time
            })
          }
          // this.getPageMessage(data.payload[0].sender_id)
          this.setState({
            NotiData: [...this.state.NotiData, ...Data],
            loading: false,
            hasMore: false,
            unread_count: data.unread_count,
            loadfrist: false,
            // activeChat: data.payload[0].sender_id,
            // mID: ,
            // activeNoti: 0
          })
          // let _idread = data.payload[0].sender_id
          // skt.emit('mark_as_read', { _idread })
          // console.log('mark_as_read_send', _idread)
          // let _copyState = this.state.NotiData[0]
          // _copyState['is_read'] = true
        }
      }
    })
  }

  // function for click send message
  onSend = () => {
    if (this.state.MessageValue === "") {
      notification['error']({
        message: 'แจ้งเตือน',
        className: "alert-notification",
        description:
          'กรุณาพิมพ์ข้อความ',
      });
    } else {
      // skt.emit('private', { _id })
      let msg = {
        "sender_id": this.state.sender_id,
        "recipient_id": this.state.recipient_id,
        "text": this.state.MessageValue,
        recipient_answer: this.state.MessageValue,
        recipient_time: moment().format('LT'),
        sender_question: '-'
      }
      // this.state.messages.push(msg)
      if (this.state.chat) {
        let _copyState = this.state.dataUser[this.state.mID]
        _copyState['recipient_answer'] = this.state.MessageValue

        let _copyStateTime = this.state.dataUser[this.state.mID]
        _copyStateTime['recipient_time'] = moment().format('LT')
      } else {
        let _copyState = this.state.NotiData[this.state.mID]
        _copyState['sender_question'] = null

        let _copyStateanswer = this.state.NotiData[this.state.mID]
        _copyStateanswer['recipient_answer'] = this.state.MessageValue

        let _copyStateanswerTimeN = this.state.NotiData[this.state.mID]
        _copyStateanswerTimeN['sender_time'] = moment().format('LT')
      }


      this.setState({
        MessageValue: '',
        // messages: this.state.messages,
      })
      PostDataMessage(this.state.sender_id, this.state.recipient_id, this.state.MessageValue).then(data => {
        console.log(data)
      })
      this.scrollToBottomSet()
    }

  }

  // function for swicth manual to chatbot or chatbot to manualchat
  setStatusChat = () => {
    this.setState({
      manualChat: !this.state.manualChat
    })
    if (this.state.manualChat) {
      skt.emit('toggle', { toggle: 1 })
    } else {
      skt.emit('toggle', { toggle: 0 })
    }

  }

  // function for active Chat user
  setActiveChat = (id, colorActive, _id, recipient_id, mID) => {
    skt.emit('private', { id })
    this.setState({
      activeChat: id,
      chActive: true,
      colorActive,
      messages: [],
      fileName: _id,
      loadingMessage: false,
      hasMoreMessage: true,
      sender_id: id,
      _id,
      recipient_id,
      pageMessage: 1,
      mID,
    }, () => this.getPageMessage(id))
  }

  // function for get number of last page chat data
  getPageMessage = id => {
    let page = 1;
    GetDatauserMessageBYID(id, page).then(data => {
      this.setState({
        pageMessage: data.page_count,
        hasMoreMessage: true
      }, () => this.getMessage(id))
    })
  }

  // function for chat history
  getMessage = id => {
    if (this.state.pageMessage <= 0) {
      this.setState({
        messages: [...this.state.messages],
        loadingMessage: false,
        hasMoreMessage: false
      })
    } else {
      GetDatauserMessageBYID(id, this.state.pageMessage).then(data => {
        if (data.payload.length === 0) {
          this.setState({
            messages: [...this.state.messages],
            loadingMessage: false,
          })
        } else {
          let Data = []
          for (let i = 0; i < data.payload.length; i++) {
            Data.push({
              _id: data.payload[i]._id,
              sender_id: data.payload[i].sender_id,
              platform_type: data.payload[i].platform_type,
              sender_question: data.payload[i].sender_question === null ? '-' : data.payload[i].sender_question,
              recipient_answer: data.payload[i].recipient_answer === null ? '-' : data.payload[i].recipient_answer,
              recipient_id: data.payload[i].recipient_id,
              sender_time: data.payload[i].sender_time === null ? '-' : data.payload[i].sender_time,
              recipient_time: data.payload[i].recipient_time === null ? '-' : data.payload[i].recipient_time,
              stamp: data.payload[i].stamp,
              __v: data.payload[i].__v
            })
          }
          this.setState({
            messages: [...Data, ...this.state.messages],
            loadingMessage: false,
          })

          if (Data.length !== 10) {
            setTimeout(() => {
              this.setState({
                pageMessage: this.state.pageMessage - 1,
                chActive: true
              }, () => this.getMessage(id))
            }, 100);
          } else {
            if (this.state.chActive) {
              this.scrollToBottomSet()
            }
          }
        }
      })
    }
  }

  // function for active Notification 
  setActiveNotification = (_id, id, sender_id, is_read) => {
    if (!is_read) {
      skt.emit('mark_as_read', { _id })
      console.log('mark_as_read_send', _id)
      let _copyState = this.state.NotiData[id]
      _copyState['is_read'] = true
      this.setState({
        activeChat: sender_id,
        activeId: id,
        pageMessage: 1,
        loadingMessage: true,
        chActive: true,
        hasMoreMessage: false,
        sender_id,
        messages: [],
        mID: id,
        isread: false
      }, () => this.getPageMessage(sender_id))
    } else {
      this.setState({
        activeChat: sender_id,
        activeId: id,
        pageMessage: 1,
        loadingMessage: true,
        chActive: true,
        hasMoreMessage: false,
        sender_id,
        messages: [],
        mID: id,
        isread: false
      }, () => this.getPageMessage(sender_id))
    }
  }

  // function for click logo Ocpb
  setValueAll = () => {
    this.setState({
      chat: true,
      NotiData: [],
      dataUser: [],
      messages: [],
      page: 1,
      loadingUser: false,
      hasMoreUser: true,
      loadefristUser: false,
      search: '',
      searchNoti: '',
      loadingMessage: true,
      hasMoreMessage: true,
      pageMessage: 1,
      chActive: true,
      activeChat: '',
      pageUser: 1,
      activesider: 'Chat'
    }, () => this.getDataUser())
  }


  onChangeMessage = (e) => {
    this.setState({
      MessageValue: e.target.value
    })
  }


  _handleKeyDown = (e) => {
    this.setState({
      MessageValue: e.target.value,
    })
    if (this.state.MessageValue !== '') {
      if (e.key === 'Enter') {
        this.onSend()
      }
    }

  }

  // function for click Icon Chat in sibar
  onSiderbar = activesider => {
    this.component.scrollTop = 0
    this.setState({
      activesider,
    })
    if (activesider === "Chat") {
      this.setState({
        chat: true,
        NotiData: [],
        dataUser: [],
        messages: [],
        page: 1,
        loadingUser: false,
        hasMoreUser: true,
        loadefristUser: true,
        search: '',
        searchNoti: '',
        // loadingMessage: true,
        hasMoreMessage: true,
        pageMessage: 1,
        chActive: true,
        pageUser: 1,
        activeChat: ''
      }, () => this.getDataUser())
    } else {
      this.setState({
        chat: false,
        dataUser: [],
        NotiData: [],
        messages: [],
        pageUser: 1,
        activeId: '',
        page: 1,
        loading: false,
        hasMore: true,
        loadfrist: true,
        search: '',
        searchNoti: '',
        pageMessage: 1,
        activeChat: '',
        loadingMessage: false,
        hasMoreMessage: false,
        chActive: true
      }, () => this.getNotification())

    }
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  handleInfiniteOnLoad = () => {
    this.setState({
      loading: true,
      hasMore: true,
      page: this.state.page + 1
    }, () => this.getNotification());

  }

  handleInfiniteOnLoadUser = () => {
    this.setState({
      loadingUser: true,
      pageUser: this.state.pageUser + 1
    }, () => this.getDataUser());
  }

  handleInfiniteOnLoadMessage = () => {
    this.setState({
      loadingMessage: true,
      pageMessage: this.state.pageMessage - 1,
      bottomfrist: false,
    }, () => this.getMessage(this.state.activeChat));
  }

  onSearch = event => {
    if (event.target.value === "") {
      this.setState({
        loadingUser: true,
        hasMoreUser: false,
        pageUser: 1,
        dataUser: [],
        search: event.target.value
      }, () => this.getDataUser())
    } else {
      this.setState({
        search: event.target.value
      })
    }
  }

  onSearchNoti = event => {
    if (event.target.value === "") {
      this.setState({
        loadfrist: true,
        page: 1,
        NotiData: [],
        messages: [],
        searchNoti: event.target.value
      }, () => this.getNotification())
    } else {
      this.setState({
        searchNoti: event.target.value
      })
    }
  }

  Logout = () => {
    localStorage.removeItem('Islogin')
    this.setState({
      loadingLogout: true
    })
    window.location.reload(true);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    });
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    }, 2000);
  };

  handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  };


  render() {
    if (this.state.loadingLogout) {
      return (
        <div className="loading-page">
          <Spin size="large" />
        </div>
      )
    } else {
      return (
        <Layout>
          <SiderFix
            collapsed={this.state.collapsed}
            activesider={this.state.activesider}
            color={this.state.color}
            onSiderbar={this.onSiderbar.bind(this)}
            unread_count={this.state.unread_count}
            setValueAll={this.setValueAll.bind(this)}
            Logout={this.Logout.bind(this)}
            showModal={this.showModal.bind(this)}
            secondColor={this.state.secondColor} />
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={430}
            theme="light"
          >
            <div className="user-list" >
              <div className="header-user">
                <div className="flex-header">
                  <span className="txt-Chat">{this.state.chat ? "Chats" : "Notification"}</span>
                </div>
                {this.state.chat ?
                  <div className="con-input">
                    <Input
                      placeholder="ค้นหาข้อความ"
                      style={{ backgroundColor: "rgba(0, 0, 0, .04)" }}
                      className="input-search"
                      value={this.state.search}
                      onChange={(e) => this.onSearch(e)}
                    />
                  </div> :
                  <div className="con-input">
                    <Input
                      placeholder="ค้นหาข้อความ"
                      style={{ backgroundColor: "rgba(0, 0, 0, .04)" }}
                      className="input-search"
                      value={this.state.searchNoti}
                      onChange={(e) => this.onSearchNoti(e)}
                    />
                  </div>
                }
              </div>
              <div className="demo-infinite-container" ref={c => (this.component = c)}>
                {this.state.chat ?
                  <SliderUser
                    search={this.state.search}
                    activeChat={this.state.activeChat}
                    setActiveChat={this.setActiveChat.bind(this)}
                    chat={this.state.chat}
                    setActiveNotification={this.setActiveNotification.bind(this)}
                    dataUser={this.state.dataUser}
                    loadingUser={this.state.loadingUser}
                    hasMoreUser={this.state.hasMoreUser}
                    loadfrist={this.state.loadfrist}
                    loadefristUser={this.state.loadefristUser}
                    handleInfiniteOnLoadUser={this.handleInfiniteOnLoadUser.bind(this)}
                    emptytext={this.state.emptytext} />
                  : <SliderNotification
                    searchNoti={this.state.searchNoti}
                    isread={this.state.isread}
                    activeChat={this.state.activeChat}
                    chat={this.state.chat}
                    NotiData={this.state.NotiData}
                    activeId={this.state.activeId}
                    setActiveNotification={this.setActiveNotification.bind(this)}
                    dataUser={this.state.dataUser}
                    loading={this.state.loading}
                    hasMore={this.state.hasMore}
                    loadfrist={this.state.loadfrist}
                    handleInfiniteOnLoad={this.handleInfiniteOnLoad.bind(this)}
                    emptytext={this.state.emptytext} />
                }
              </div>
            </div>
          </Sider>
          <Layout >
            <Header
              activesider={this.state.activesider}
              colorActive={this.state.colorActive}
              setStatusChat={this.setStatusChat.bind(this)}
              activeChat={this.state.activeChat}
              loadingMessage={this.state.loadingMessage}
              messages={this.state.messages}
              fileName={this.state.fileName}
              manualChat={this.state.manualChat} />
            {this.state.activeChat === "" ?
              <div className="chat" style={{ backgroundColor: "#ffffff" }} >
                <div className="chat-infinite-container">

                </div>
              </div>
              :
              <div className="chat" style={{ backgroundColor: "#ffffff" }} >
                <div className="chat-infinite-container">
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    useWindow={false}
                    loadMore={() => this.handleInfiniteOnLoadMessage()}
                    hasMore={!this.state.loadingMessage && this.state.hasMoreMessage}
                    isReverse={true}
                  >
                    {this.state.loadingMessage && this.state.hasMoreMessage && (
                      <div className="loading-chat">
                        <Spin />
                      </div>
                    )}
                    <List
                      locale={{
                        emptyText: (
                          <div>
                            {/* <WechatFilled style={{ fontSize: 34 }} />
                        <p style={{ fontWeight: '500', marginBottom:0 }}>No new chats</p> */}
                          </div>
                        )
                      }}
                      dataSource={this.state.messages}
                      renderItem={(item, idx) => (
                        <div ref={(ref) => this['_div' + idx] = ref} key={idx} >
                          {item.sender_question === '-' || item.sender_question === null ? null :
                            <div className="flex-start-chat">
                              <div className="chat-left">
                                <p className="txt-text">{item.sender_question}</p>
                                <p className="txt-time-left">{item.sender_time}</p>
                              </div>
                            </div>}
                          {item.recipient_answer === '-' || item.recipient_answer === null ? null :
                            <div className="flex-end-chat">
                              <div className="chat-right" style={{ marginTop: 15 }}>
                                <p className="txt-text">{item.recipient_answer}</p>
                                <p className="txt-time-right" style={{ color: 'rgb(33 33 33)' }}>{item.recipient_time}</p>
                              </div>
                            </div>}
                        </div>
                      )} >
                      {this.state.loadChatLeft?
                      <div className="flex-start-chat">
                        <div className="chat-left">
                          <p className="txt-text">{'กำลังพิมพ์ ...'}</p>
                        </div>
                      </div>
                      :null}
                      {this.state.loadChatRight ?
                        <div className="flex-end-chat">
                          <div className="chat-right" style={{ marginTop: 15 }}>
                            <p className="txt-text">{'กำลังพิมพ์ ...'}</p>
                          </div>
                        </div>
                        : null}
                    </List>
                  </InfiniteScroll>
                </div>
              </div>
            }

            <FooterPage
              activeChat={this.state.activeChat}
              manualChat={this.state.manualChat}
              MessageValue={this.state.MessageValue}
              onChangeMessage={this.onChangeMessage.bind(this)}
              _handleKeyDown={this._handleKeyDown.bind(this)}
              onSend={this.onSend.bind(this)}
            />
            <Modal
              title="ออกจากระบบ"
              visible={this.state.visible}
              onOk={() => this.Logout()}
              onCancel={this.handleCancel}
              okText="ออกจากระบบ"
              cancelText="ยกเลิก"
            >
              <p>ท่านต้องการออกจากระบบใช่หรือไม่ ?</p>
            </Modal>
          </Layout>
        </Layout>

      );
    }

  }
}

export default SiderDemo