import React, { useEffect } from 'react'
import { Row, Col, Avatar, Spin, List } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { WechatFilled } from '@ant-design/icons';

const SliderUser = props => {

  useEffect(() => {
    // ref.current.scrollTo(0, 0)
  }, [])

  return (
    <InfiniteScroll
      initialLoad={false}
      pageStart={0}
      useWindow={false}
      loadMore={() => props.handleInfiniteOnLoadUser()}
      hasMore={!props.loadingUser && props.hasMoreUser}>
      {props.loadefristUser ?
        <div className="loading-chat">
          <Spin />
        </div> :
        <List
          locale={{
            emptyText: (
              <div>
                <WechatFilled style={{ fontSize: 34 }} />
                <p style={{ fontWeight: '500' }}>No new chats</p>
              </div>
            )
          }}
          dataSource={props.dataUser.filter(
            data =>
              data.sender_question === null ?
                data.recipient_answer.indexOf(props.search) !== -1
                : data.sender_question.indexOf(props.search) !== -1
          )}
          renderItem={(item, id) => (
            <div
              className={props.activeChat === item.sender_id ? "card-user-active" : "card-user"}
              onClick={() => props.setActiveChat(item.sender_id, item.color, item._id, item.recipient_id, id)}>
              <Row>
                <Col flex="60px" className="img-col">
                  <Avatar size={40} style={item.color}>{"U" + item.sender_id.substring(0, 1)}</Avatar>
                </Col>
                <Col flex="auto" className="img-col">
                  <Row>
                    <Col flex="auto" className="p-col">
                      <h5 className="user-id-chat" style={{ fontWeight: "normal" }}>{"User-" + item.sender_id}</h5>
                      <p className="subtitle-txt" style={{ fontWeight: "normal" }}>{item.recipient_answer === null ? item.sender_question : item.recipient_answer}</p>
                    </Col>
                    <Col flex="80px" className='Badge-col'>
                      <p className="p-time-chat" style={{ fontWeight: "normal" }}>{item.recipient_time === null ? item.sender_time : item.recipient_time}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        />
      }
      {props.loadingUser && props.hasMoreUser && (
        <div className="card-user">
          <Spin />
        </div>
      )}

    </InfiniteScroll>
  )
}

export default SliderUser