import { serviceApi } from "../../../util/server"


export const GetDataNotification = (page) => {
  const requestOptions = {
    method: 'GET'
  };
  return (
    fetch(serviceApi() + "notifications?p=" + page, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        return responseJson
      })
      .catch(error => {
        console.log(error);
      })
  )
}

export const GetDatauserMessage = (page) => {
  const requestOptions = {
    method: 'GET'
  };
  return (
    fetch(serviceApi() + "histories?p=" + page, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        return responseJson
      })
      .catch(error => {
        console.log(error);
      })
  )
}

export const GetDatauserMessageBYID = (id, page) => {
  const requestOptions = {
    method: 'GET'
  };
  return (
    fetch(serviceApi() + "histories/" + id + "?p=" + page, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        return responseJson
      })
      .catch(error => {
        console.log(error);
      })
  )
}

export const PostDataMessage = (sender_id,recipient_id,text) => {
  console.log(sender_id,recipient_id,text)
  let body = {
    sender_id,
    recipient_id,
    text,
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
};
  return (
    fetch(serviceApi() + "message", requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        return responseJson
      })
      .catch(error => {
        console.log(error);
      })
  )
}
