

import React, { Component } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Login from './pages/login'
import ChatPage from "./pages/chat"


export default class App extends Component {
  render() {
    console.log(localStorage.getItem('Islogin'))
    return (
      <Router>
        <Switch>
          <Route path="/chat" exact={true}>
            {localStorage.getItem('Islogin') ? <ChatPage /> : <Redirect exact to={'/login'} />}
          </Route>
          <Route path="/" exact={true}>
            <Redirect exact to={'/chat'} />
          </Route> 
          <Route path="/login" exact={true}>
            {localStorage.getItem('Islogin') ?  <Redirect exact to={'/chat'} />: <Login />}
          </Route>
        </Switch>
      </Router>
    )
  }
}
