import React, { useState }  from 'react'
import './styles/login.css'
import { Form, Input, Button, notification, Spin } from 'antd'
import { UserOutlined, LockOutlined,  } from '@ant-design/icons'
import { DataUser } from "../../util/dataMockup"
const Login = () => {

    const [loading , SetLoading] = useState('1')
    const [ Userdata ] = useState(DataUser) 

    const onFinish = values => {
        const result = Userdata.find( Element => Element.user === values.username &&  Element.pass === values.password);
        if(result !== undefined){
            localStorage.setItem('Islogin', true)
                SetLoading('2')
                window.location.reload(true);
        }else{
            notification['error']({
                message: 'แจ้งเตือน',
                className: "alert-notification",
                description:
                    'บัญชีผู้ใช้ไม่ถูกต้อง',
            });
        }
    }

    if(loading === '2'){
        return(
            <div className="loading-page">
                <Spin size="large"/>
            </div> 
        )
    }else{
        return (
            <div className="con-Login">
                <div className="purple-square-container">
                    <div>
                        <div className="purple-square">
                            <div className="img-logo">
                                <img src="https://ocpbconnect.ocpb.go.th/images/logo/icon%20app_edit.png" className="logo-sty" alt="icon_app"/>
                            </div>
                            <div className="con-form">
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[{ required: true, message: 'Please input your Username!' }]}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your Password!' }]}
                                    >
                                        <Input
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
    
                                    <Form.Item>
                                        <div className="center-btn">
                                            <Button type="primary" htmlType="submit" className="login-form-button btn-login">
                                                เข้าสู่ระบบ
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default Login
