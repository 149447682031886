import React, { useEffect } from 'react'
import {  Row, Col, Spin, List } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { WarningOutlined, BellFilled } from '@ant-design/icons'

const SliderUser = props => {

  useEffect(() => {
    // ref.current.scrollTo(0, 0)
  }, [])
  return (
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            useWindow={false}
            loadMore={() => props.handleInfiniteOnLoad()}
            hasMore={!props.loading && props.hasMore}>
            {props.loadfrist ? 
                <div className="loading-frist">
                    <Spin />
                </div>
            :
              <List
                dataSource={props.NotiData.filter(
                  data =>
                    data.sender_question === null ? 
                    data.recipient_answer.indexOf(props.searchNoti) !== -1
                    :data.sender_question.indexOf(props.searchNoti) !== -1
                )}
                locale={{emptyText : (
                <div>
                  <BellFilled style={{ fontSize: 32}}/>
                    <p style={{ fontWeight:'500'}}>{props.emptytext === '' ? "No Notification" : props.emptytext}</p>
                </div>
                )}}
                renderItem={(item, id) => (
                  <div className={props.activeId === id ? "card-user-active" : "card-user"} onClick={() => props.setActiveNotification(item._id, id, item.sender_id, item.is_read)}>
                    <Row>
                      <Col flex="60px" className="img-col">
                        <WarningOutlined style={{ fontSize: 26, color: '#104898', opacity: !item.is_read ? '1' : '1'}} />
                      </Col>
                      <Col flex="auto" className="img-col">
                        <Row>
                          <Col flex="auto" className="p-col">
                            <div className="flex-dot-txt">
                                <h5 className="dot-txt" style={{ fontWeight: !item.is_read ? 'bold' : 'normal', color: '#4d5156' }}>{"ไม่สามารถตอบคำถามได้"}</h5>
                            </div>
                            <p className="subtitle-txt" style={{ fontWeight: !item.is_read ? 'bold' : 'normal', color: !item.is_read ?  '#4d5156' :'#969696' }}>{item.sender_question === null ? item.recipient_answer :  item.sender_question}</p>
                          </Col>
                          <Col flex="60px" className='Badge-col'>
                          <p className="p-time-noti" style={{ fontWeight: !item.is_read ? 'bold' : 'normal', color: !item.is_read ?  '#4d5156' :'#969696'  }}>{item.sender_time}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              />
                }
            {props.loading && props.hasMore && (
              <div className="card-user">
                <Spin />
              </div>
            )}
          </InfiniteScroll>
  )
}

export default SliderUser