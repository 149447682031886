export const Color = [
    {
      color: '#ffffff',
      backgroundColor: '#751aff'
    },
    {
      color: "#ffffff",
      backgroundColor: "#eca1a6"
    },
    {
      color: "#ffffff",
      backgroundColor: '#bb7409'
    },
    {
      color: "#ffffff",
      backgroundColor: "#bb7409"
    },
    {
      color: "#ffffff",
      backgroundColor: '#097abb'
    },
    {
      color: "#ffffff",
      backgroundColor: '#09bb87'
    },
    {
        color: '#ffffff',
        backgroundColor: '#751aff'
      },
      {
        color: "#ffffff",
        backgroundColor: "#eca1a6"
      },
      {
        color: "#ffffff",
        backgroundColor: '#bc5a45'
      },
      {
        color: "#ffffff",
        backgroundColor: "#7e4a35"
      },
      {
        color: "#ffffff",
        backgroundColor: '#838060'
      },
      {
        color: "#ffffff",
        backgroundColor: '#563f46'
      },
        {
        color: '#ffffff',
        backgroundColor: '#d96459'
      },
      {
        color: "#ffffff",
        backgroundColor: "#e06377"
      },
      {
        color: "#ffffff",
        backgroundColor: '#bc5a45'
      },
      {
        color: "#ffffff",
        backgroundColor: "#7e4a35"
      },
      {
        color: "#ffffff",
        backgroundColor: '#5b9aa0'
      },
      {
        color: "#ffffff",
        backgroundColor: '#b8a9c9'
      }
  ];
