import React, { useState } from "react"
import { Layout, Tooltip, Avatar, Select, message } from 'antd'
import { CSVLink } from "react-csv"
import { CloudDownloadOutlined, CommentOutlined, AliwangwangOutlined } from '@ant-design/icons'

const { Header } = Layout

const headers = [
    { label: "id", key: "_id" },
    { label: "platform_type", key: "platform_type" },
    { label: "sender_id", key: "sender_id" },
    { label: "recipient_id", key: "recipient_id" },
    { label: "sender_question", key: "sender_question" },
    { label: "recipient_answer", key: "recipient_answer" },
    { label: "sender_time", key: "sender_time" },
    { label: "recipient_time", key: "recipient_time" }
];

const { Option } = Select;
const HeaderChat = props => {

    const [color, setColor] = useState('#828282')
    const [colorSecond, setColorSecond] = useState(color)
    const [items ] = useState(['Superadmin', 'admin', 'Ocpb admin'])
    const [SelectValue, setSelectValue ] = useState('')
    const [openD , setOpen] = useState(false)
    const onMouseEnter = () => setColor('#104898')

    const onMouseLeave = () => setColor('#828282')

    const onSelect = (value, option) => {
        setSelectValue(value)
        if(value === "n"){
            setSelectValue("")
            message.success('ยกเลิกการส่งต่อ');
        }else{
            message.success('เลือกให้ '+option.children+' ตอบคำถามผู้บริโภค');
        }
    }

    const CencelSend = () => {
        setOpen(false)
        setSelectValue("")
        message.success('ยกเลิกการส่งต่อ');
    }

    if (props.activeChat === '') {
        return (
            <Header className="site-layout-background" />
        )
    } else {
        return (
            <Header className="site-layout-sub-header-background" >
                <div className="center-header">
                    <div className="flex-userActive">
                        <Avatar size={40} style={props.colorActive}>U{props.activeChat.substring(0, 1)}</Avatar>
                        <div className="center-header">
                            <p className="header-id" style={{ marginLeft: 10 }}>{props.activeChat === "" ? "" : "User-" + props.activeChat}</p>
                        </div>
                    </div>
                </div>
                <div className="center-p">
                    <div className="btn-two">
                        <Select 
                            placeholder="เลือกส่งต่อให้ ... " 
                            onChange={onSelect}
                            onDropdownVisibleChange={() => setOpen(!SelectValue)}
                            style={{ width: 150, marginRight:10 }} 
                            size="large"
                            // open={openD}
                            value={SelectValue}
                            // dropdownRender={menu => (
                            //     <div>
                            //       {menu}
                            //       <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            //         <a
                            //           style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            //           onClick={() => CencelSend()}
                            //         >
                            //          ยกเลิกการส่งต่อ
                            //         </a>
                            //       </div>
                            //     </div>
                            //   )}
                            >
                                {/* {items.map(item => (
                                    <Option key={item}>{item}</Option>
                                ))} */}
                            <Option value="a">Superadmin</Option>
                            <Option value="b">Admin</Option>
                            <Option value="c">Ocpb admin</Option>
                            <Option value="n">ยกเลิกการส่งต่อ</Option>
                        </Select>
                        <Tooltip placement="bottomLeft" title={"Export to CSV"} overlayStyle={{ fontSize: 12 }}>
                            <CSVLink data={props.messages} headers={headers} filename={props.fileName}>
                                <div className="btn-outline" onClick={() => props.setStatusChat()} style={{ marginRight: 10 }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                                    <CloudDownloadOutlined style={{ fontSize: 20, color }} />
                                </div>
                            </CSVLink>
                        </Tooltip>
                        <Tooltip placement="bottomLeft" title={!props.manualChat ? "Switch to chat" : "Switch to chatbot"} overlayStyle={{ fontSize: 12 }}>
                            <div className="btn-outline" onClick={() => props.setStatusChat()} onMouseEnter={() => setColorSecond('#104898')} onMouseLeave={() => setColorSecond(color)}>
                                {props.manualChat ? <AliwangwangOutlined style={{ fontSize: 20, color: colorSecond }} /> :
                                    <CommentOutlined style={{ fontSize: 20, color: colorSecond }} />}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </Header>
        )
    }

}

export default HeaderChat 
