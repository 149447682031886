import React, { useState } from 'react'
import { Layout, Badge, } from 'antd'
import { BellOutlined, CommentOutlined, LogoutOutlined } from '@ant-design/icons'
import { OcpbIcon } from "../../util/pathImage"

const { Sider } = Layout

const SiderFix = props => {
  const [color, setColor] = useState('#828282')
  const [secondColor, setSecondColor] = useState('#828282')
  const [logoutColor, setLoguotColor] = useState('#828282')

  return (
    <Sider trigger={null} collapsible collapsed={props.collapsed} theme="light" width={100} className="sider-left" >
      <div className="logo-top-chat w-top-chat" onClick={() => props.setValueAll()}>
        <img src={OcpbIcon()} alt="icon" style={{ width: 58 }} />
      </div>
      <div>
        <div className="icon-chat-sider">
          <div
            className={props.activesider === "Chat" ? "sider-chatleft-active" : "div-sider-chat"}
            onClick={() => props.onSiderbar('Chat')} onMouseEnter={() => setColor('#104898')} onMouseLeave={() => setColor('#828282')}>
            <CommentOutlined style={{ fontSize: 22, color: props.activesider === "Chat" ? '#104898' : color }} />
          </div>
        </div>
        <div className="icon-chat-sider" >
          <div
            className={props.activesider === "Notification" ? "sider-chatleft-active" : "div-sider-chat"}
            onClick={() => props.onSiderbar('Notification')} onMouseEnter={() => setSecondColor('#104898')} onMouseLeave={() => setSecondColor('#828282')}>
            <Badge count={props.unread_count} style={{ fontSize: 11 }}>
              <BellOutlined style={{ fontSize: 22, color: props.activesider === 'Notification' ? '#104898' : secondColor }} />
            </Badge>
          </div>
        </div>
        <div className="icon-chat-sider" >
          <div
            className={props.activesider === "Logout" ? "sider-chatleft-active" : "div-sider-chat"}
            onClick={() => props.showModal()} onMouseEnter={() => setLoguotColor('#104898')} onMouseLeave={() => setLoguotColor('#828282')}>
            <LogoutOutlined style={{ fontSize: 22, color: props.activesider === 'Logout' ? '#104898' : logoutColor }} />
          </div>
        </div>
      </div>
    </Sider>
  )
}

export default SiderFix