import React from 'react'
import { Layout, Input,  } from 'antd'

import { IconSend } from  "../../util/pathImage/index"

const { Footer } = Layout
const { TextArea } = Input;

const FooterPage = props => {
    if(props.activeChat === ""){
        return (
            <Footer className={"footer-chat-nodata"}></Footer>
        )       
    }else{
        return (
            <Footer className={"footer-chat"}>
                <TextArea 
                    rows={4} 
                    className="input-chat"
                    placeholder={props.manualChat ? 'Chatbot กำลังตอบคำถามอยู่ในขณะนี้ ' : 'พิมพ์ข้อความ'}
                    {...(props.manualChat ? { disabled: true } : null)}
                    value={props.MessageValue}
                    onChange={(e) => props.onChangeMessage(e)}
                    onKeyDown={(e) => props._handleKeyDown(e)} />
                <div className="center-btn-send">
                    <div className="send-img" {...(props.manualChat ? null : { onClick: () => props.onSend() })}>
                        <img src={IconSend()} style={{ width: 20, height: 20 }} alt="icon-send" />
                    </div>
                </div>
            </Footer>
        )
    }
    
}
export default FooterPage

