export const DataUser = [
    {
        user:'superadmin',
        pass:'1234'
    },
    {
        user:'admin',
        pass:'1234'
    },
    {
        user:'ocpbadmin',
        pass:'1234'
    }
]